import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components1/bio'
import Layout from '../components1/layout'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import Seo from '../components1/seo'
/*import { rhythm } from '../utils/typography'*/
import blogStyles from "./index.module.css"
const BlogIndex = (props) => {
  
    const { data } = props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    
    return (
      <Layout location={props.location} title={siteTitle}>
        <Seo
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        
        
        {posts.map(({ node }) => {
          {/*const title = node.frontmatter.title || node.fields.slug*/}
          const title = `Faster than the speed of light?`
          return (
            
            <div key={node.fields.slug}>
              <h3 style={{textAlign:`center`, margin:`0px 0px`, textAlign:'center'}}>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <p style={{textAlign:`center`, marginBottom:`20px`, color: 'rgba(0,0,0,0.5)', textAlign:'center'}}>{node.frontmatter.date}</p>
              
              <MDXRenderer>{node.body}</MDXRenderer>
              
            </div>
            
          )
        })}
        
      </Layout>
    )
  
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: { slug: { eq: "home/home" }}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          body
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            cover {
              img
              source
              url
            }
          }
        }
      }
    }
  }
`
